import React, { RefCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Soundpiece from '@/components/sections/soundpiece-playlist/components/soundpiece';
import { AudioTimeUpdateEvent } from '@/lib/hooks/use-audio-player';
import {
  selectAudioPlayerIsPlaying,
  selectPlayerCurrentAudio,
} from '@/store/slices/player';
import {
  ContentFeedEntrySoundpiece,
  PlaylistItem,
} from '@/types/views/generic';

interface SoundpieceTeaserProps extends ContentFeedEntrySoundpiece {
  imageWrapperRef: RefCallback<HTMLDivElement>;
  audioUrl: string;
  coverUrl: string;
  onPlay: (
    index: number,
    localEntry: ContentFeedEntrySoundpiece | PlaylistItem,
  ) => void;
  onPause: () => void;
  changeEvent?: AudioTimeUpdateEvent;
  addToAutoPlaylist: (
    entry: PlaylistItem | ContentFeedEntrySoundpiece,
    index?: number,
  ) => void;
  index: string;
}

const SoundpieceTeaser = (props: SoundpieceTeaserProps) => {
  const { id, addToAutoPlaylist, onPlay, index, changeEvent } = props;

  const indexNumber = Number(index);

  const currentAudio = useSelector(selectPlayerCurrentAudio);
  const isAudioPlaying = useSelector(selectAudioPlayerIsPlaying);

  useEffect(() => {
    addToAutoPlaylist(props, indexNumber);
  }, [addToAutoPlaylist, props, indexNumber]);

  return (
    <Soundpiece
      {...props}
      key={id}
      isActive={
        !!currentAudio &&
        'id' in currentAudio &&
        currentAudio.id === id &&
        isAudioPlaying
      }
      onPlay={() => onPlay(indexNumber, props)}
      audioTimeUpdateEvent={changeEvent}
    />
  );
};

export default SoundpieceTeaser;
