import { css } from '@emotion/css';
import cls from 'classnames';

import { screens } from '@/../tailwind/theme';

const mobileGridTemplate = css`
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'image date actions'
    'title title title';
`;

const mobileGridTemplateWithTags = css`
  grid-template-rows: 1fr auto auto;
  grid-template-areas:
    'image date actions'
    'title title title'
    'tags tags tags';
`;

const itemGrid = (areTagsAvailable: boolean) => css`
  grid-template-columns: 56px 1fr 1fr;
  gap: 0.5rem;
  ${areTagsAvailable ? mobileGridTemplateWithTags : mobileGridTemplate};

  @media (min-width: ${screens.md}) {
    grid-template-columns: 126px minmax(0, 1fr) 0.375fr;
    grid-template-rows: 0.75fr auto 1fr;
    gap: 0.25rem 1rem;
    grid-template-areas:
      'image date actions'
      'image title actions'
      'image tags actions';
  }
`;

const imageWrapper = css`
  grid-area: image;
`;

const date = css`
  grid-area: date;
`;

const actions = css`
  grid-area: actions;
`;

const title = css`
  grid-area: title;
`;

const tags = css`
  grid-area: tags;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const styles = {
  listItem: (areTagsAvailable: boolean) =>
    `grid ${itemGrid(
      areTagsAvailable,
    )} p-2 bg-black-light bg-opacity-30 mb-4 rounded-2 md:p-0 hover:bg-gray-1 hover:bg-opacity-30`,
  imageWrapper: cls(imageWrapper, 'rounded-l-2 overflow-hidden'),
  image: 'aspect aspect-1-1',
  date: `${date} self-center text-overline text-pink md:self-end pl-2 md:pl-0`,
  dateTime: 'text-pink block md:inline md:pl-2',
  duration: 'text-gray-2 block md:inline md:pl-2',
  timeLeft: 'text-gray-2 block md:inline',
  actions: `${actions} self-center justify-self-end flex items-center`,
  entryTitle: `${title} text-lead line-clamp-2 md:line-clamp-1`,
  tags: `${tags} flex gap-x-2`,
  audioProgressBar: 'w-10 h-10 md:w-14 md:h-14 mr-4 md:mr-6 ml-6',
};

export default styles;
