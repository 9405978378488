import cls from 'classnames';
import { FC, RefCallback } from 'react';

import MobileTicketModal from '@/components/account/mobile-ticket-modal';
import NormalButton from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import DownloadIcon from '@/components/icons/download-icon';
import Image from '@/components/image';
import Modal from '@/components/modal';
import useModal from '@/components/modal/use-modal';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useBreakpoint from '@/lib/hooks/use-breakpoint';
import useVariables from '@/lib/hooks/use-variables';
import { ContentFeedEntryPrize } from '@/types/views/generic';

interface TicketTeaserProps extends ContentFeedEntryPrize {
  imageWrapperRef: RefCallback<HTMLDivElement>;
}

const TicketTeaser: FC<TicketTeaserProps> = ({
  image,
  title,
  description,
  amount,
  status,
  categories,
  ticketUrl,
  tickets,
  details,
  modus,
  type,
  imageWrapperRef,
}) => {
  const { isModalOpen, setIsModalOpen } = useModal();

  const openDetails = () => setIsModalOpen(true);
  const closeDetails = () => setIsModalOpen(false);

  const isPrize = type === 'prize';
  const { isMobile } = useBreakpoint();

  const variables = useVariables();

  const content = (
    <>
      <div
        className="overflow-hidden w-full rounded-4 relative cursor-pointer"
        ref={imageWrapperRef}
      >
        <div
          className={cls(
            'h-full w-full ticket-placeholder-gradient',
            !image && 'aspect aspect-16-9',
          )}
        >
          {image && (
            <Image
              src={image}
              alt={title}
              basicLoader
              objectFit="cover"
              className="aspect aspect-16-9"
            />
          )}
        </div>

        <div className="absolute inset-0 invisible group-hover:visible flex items-center justify-center z-10">
          <div className="rounded-full bg-black bg-opacity-60 w-[5rem] h-[5rem] xl:h-[7rem] xl:w-[7rem] flex items-center justify-center">
            <DownloadIcon className="w-8 h-8 xl:w-16 xl:h-16" />
          </div>
        </div>
      </div>

      <div className="mt-2 font-semibold">
        <h3 className="text-body2">{title}</h3>

        {(status === 'confirmed' || status === 'closed') && (
          <>
            {amount >= 0 && (
              <h4 className="text-gray-3">
                {getVariablePlainText(
                  variables['account-tickets-count-text'],
                ) || 'Tickets: '}
                <span className="text-white">{amount}</span>
              </h4>
            )}

            {categories && (
              <h4 className="text-gray-3">
                {getVariablePlainText(
                  variables['account-tickets-category-text'],
                ) || 'Kategorie: '}
                <span className="text-white">{categories}</span>
              </h4>
            )}
          </>
        )}
      </div>
    </>
  );

  if (isPrize || status === 'expired')
    return (
      <>
        {isModalOpen && (
          <Modal onClose={closeDetails} showCloseIcon={false}>
            <div className="max-w-[1158px] w-full h-full flex flex-col">
              <div className="h-[250px] w-full ticket-placeholder-gradient">
                {image && (
                  <Image
                    src={image}
                    alt={title}
                    basicLoader
                    objectFit="cover"
                    className="h-[250px] w-full"
                  />
                )}
              </div>

              <div className="px-4 py-12 flex flex-col items-center h-full">
                <h3 className="text-h3 text-center">{title}</h3>

                <p className="text-body1 mt-4">{description}</p>

                <NormalButton
                  className="mt-auto md:mt-8"
                  text="Schliessen"
                  onClick={closeDetails}
                />
              </div>
            </div>
          </Modal>
        )}

        <div onClick={openDetails}>{content}</div>
      </>
    );

  if (isMobile) {
    return (
      <>
        {isModalOpen && image && tickets && modus && details && (
          <MobileTicketModal
            image={image}
            title={title}
            tickets={tickets}
            modus={modus}
            details={details}
            amount={amount}
            status={status}
            categories={categories}
            isOpen={isModalOpen}
            closeModal={closeDetails}
          />
        )}
        <div onClick={openDetails}>{content}</div>
      </>
    );
  }

  return (
    <>
      {ticketUrl && (
        <CustomLink href={ticketUrl} isExternal className="group">
          {content}
        </CustomLink>
      )}
    </>
  );
};

export default TicketTeaser;
