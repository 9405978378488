import cls from 'classnames';
import { FC, memo } from 'react';

import Advertisement from '../..';
import styles from './styles';

interface CommonAdBoxProps {
  className?: string;
  isCenter?: boolean;
}

const CommonAdBox: FC<CommonAdBoxProps> = ({
  children,
  className = '',
  isCenter = true,
}) => (
  <aside
    className={cls(styles.commonAdBox, className, isCenter && 'justify-center')}
    style={{ padding: 0 }}
  >
    {/* TODO: Probably could use Advertisement component here instead, refactor */}
    {children}
  </aside>
);

interface WideBoardAdProps {
  position?: 1 | 2 | 3;
  targetingPos?: number;
}

const WideBoardAd: FC<WideBoardAdProps> = ({ position = 1, targetingPos }) => {
  const secondMrPosition = position === 2 ? `2-MR` : position;

  return (
    <CommonAdBox>
      <Advertisement
        slot={`WB_${secondMrPosition}`}
        {...(targetingPos && { slotId: `ad-sba-slot-wb2-mr-${targetingPos}` })}
      />
    </CommonAdBox>
  );
};

interface SidebarAdProps {
  position?: 'left' | 'right';
  slotType: 'SBA_1' | 'SBA_2';
}

export const SidebarAd = ({ position = 'right', slotType }: SidebarAdProps) => (
  // TODO: Needs to adjust back to top-28 on scroll position other than 0
  <div className={styles.sideAdTopContainer(position)}>
    <div className={styles.sideAdBottomContainer}>
      {/* TODO: Might have to remove constant w-full h-full so Ads.helper methods work as expected */}
      <div id={`ads-papi-sidebar-${position}`} className="w-full h-full">
        <Advertisement slot={slotType} />
      </div>
    </div>
  </div>
);

type MobileMediumRectangleAdProps = {
  position: 1 | 2;
  targetingPos?: number;
  isWatchExperienceAd?: boolean;
};

interface MobileHalfPageAdProps {
  targetingPos?: number;
  slot: 'MHPA_2' | 'MHPA_3';
}

const MobileHalfPageAd: FC<MobileHalfPageAdProps> = ({
  targetingPos,
  slot,
}) => (
  <CommonAdBox>
    <Advertisement
      slot={slot}
      {...(targetingPos && { slotId: `ad-sba-slot-mhpa2-${targetingPos}` })}
    />
  </CommonAdBox>
);

type MediumRectangleAdProps = WideBoardAdProps & {
  className?: string;
  isWatchExperienceAd?: boolean;
};

interface WrapperComponent {
  children: JSX.Element;
}

const MediumRectangleAd: FC<MediumRectangleAdProps> = memo(
  ({
    position = 1,
    targetingPos,
    className = '',
    isWatchExperienceAd = false,
  }) => {
    const WrapperComponent: FC<WrapperComponent> = ({ children }) =>
      isWatchExperienceAd ? (
        <div
          className={cls(
            'aspect aspect-3-4 w-full bg-black-light rounded-4',
            'md:aspect-3-2 xl:aspect-3-4 2xl:aspect-3-2',
          )}
        >
          <div className="flex items-center justify-center">{children}</div>
        </div>
      ) : (
        children
      );

    return (
      <CommonAdBox
        className={cls(
          'hidden md:flex flex-col justify-start',
          isWatchExperienceAd && 'md:col-span-2 xl:col-span-1 2xl:col-span-2',
          className,
        )}
        isCenter={false}
      >
        <WrapperComponent>
          <Advertisement
            slot={`MR_${position}`}
            {...(targetingPos && { slotId: `ad-sba-slot-mr2-${targetingPos}` })}
          />
        </WrapperComponent>

        {isWatchExperienceAd && (
          <div className="text-gray-2 text-overline mt-2">Werbung</div>
        )}
      </CommonAdBox>
    );
  },
);

const MobileMediumRectangleAd: FC<MobileMediumRectangleAdProps> = memo(
  ({ position = 1, targetingPos, isWatchExperienceAd = false }) => (
    <CommonAdBox
      className={cls(
        'md:hidden',
        isWatchExperienceAd && 'col-span-2 -mx-[1.25rem]',
      )}
    >
      <Advertisement
        slot={`MMR_${position}`}
        {...(targetingPos && { slotId: `ad-sba-slot-mmr2-${targetingPos}` })}
      />
    </CommonAdBox>
  ),
);

const SectionAds = {
  WideBoardAd,
  SidebarAd,
  MobileMediumRectangleAd,
  MobileHalfPageAd,
  MediumRectangleAd,
};

export default SectionAds;
