import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import React, { FC, isValidElement } from 'react';
import { useSelector } from 'react-redux';

import NormalButton, {
  ButtonEAIR,
  ButtonEDS,
  ButtonELS,
  ButtonESN,
  ButtonMain,
} from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import LotteryFormButton from '@/components/lottery-form/button';
import RichTextRenderer, {
  renderTextLink,
} from '@/components/rich-text-renderer';
import { selectGlobalPageTheme } from '@/store/slices/global';
import { BoxTheme, BoxType } from '@/types/cms';
import {
  LotteryFormButton as LotteryFormButtonType,
  PageTheme,
  TextLink,
} from '@/types/views/generic';

import BoxArticle from '../box-article';
import styles from './styles';

const buttonProps = (
  buttonIndex: number,
  pageTheme: PageTheme | null,
): (ButtonMain | ButtonEAIR | ButtonESN | ButtonEDS | ButtonELS) & {
  className?: string;
} => {
  if (pageTheme === 'eair') {
    return {
      variant: 'eair',
      color: buttonIndex === 0 ? 'coral' : 'white',
    };
  }

  if (pageTheme === 'esn') {
    return {
      variant: 'esn',
      color: buttonIndex === 0 ? 'mint' : 'blue',
    };
  }

  if (pageTheme === 'eds') {
    return {
      variant: 'eds',
      color: buttonIndex === 0 ? 'pink' : 'black',
    };
  }

  if (pageTheme === 'els') {
    return {
      variant: 'els',
      color: buttonIndex === 0 ? 'light-blue' : 'blue',
    };
  }

  return {
    variant: buttonIndex === 0 ? 'default' : 'ghost',
    color: 'white',
    className:
      buttonIndex === 0
        ? styles.secondaryThemePrimaryButton
        : styles.secondaryThemeSecondaryButton,
  };
};

const richTextOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="text-red-light mb-2 last:mb-0">{children}</p>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <h3 className="text-h4 md:text-h5 text-pink mb-2 mt-8 first:mt-0">
        {children}
      </h3>
    ),
    [INLINES.HYPERLINK]: (node) =>
      renderTextLink(node, 'text-red-medium hover:text-red'),
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      if (node.data.target.componentType !== 'image') return null;

      const { image } = node.data.target;
      const offset = image.height / 2 - 6;

      // Hack for display spaces and image centered
      return (
        <span
          className="relative inline-block"
          style={{
            top: offset,
            marginBottom: offset,
            marginTop: `-${offset}px`,
          }}
        >
          <Image
            src={image.src}
            useIntrinsicSize
            width={image.width}
            height={image.height}
          />
        </span>
      );
    },
  },
};

const isLotteryFormButton = (
  link: LotteryFormButtonType | TextLink,
): link is LotteryFormButtonType =>
  (link as LotteryFormButtonType)?.componentType === 'lotteryFormButton';

const isDocumentDescription = (
  description: Document | JSX.Element,
): description is Document =>
  'nodeType' in description && description.nodeType === 'document';

const isElementDescription = (
  description: Document | JSX.Element,
): description is JSX.Element => isValidElement(description);

const MAX_NUMBER_OF_BUTTONS = 4;

interface BoxContentProps {
  title: string;
  description?: Document | JSX.Element;
  overlineText?: string;
  links: Array<TextLink | LotteryFormButtonType>;
  theme: BoxTheme;
  type: BoxType;
  textClassNames?: string;
}

const BoxContent: FC<BoxContentProps> = ({
  title,
  description,
  overlineText,
  links,
  theme,
  type,
  textClassNames,
}) => {
  const pageTheme = useSelector(selectGlobalPageTheme);

  return (
    <div>
      {overlineText && (
        <div className="mb-4">
          <span className={styles.overlineText(theme)}>{overlineText}</span>
        </div>
      )}

      <BoxArticle
        title={title}
        theme={theme}
        type={type}
        className={links?.length ? 'mb-6' : ''}
        textClassNames={textClassNames}
      >
        {description && isDocumentDescription(description) && (
          <RichTextRenderer
            body={description}
            {...(type === BoxType.Contact && {
              options: richTextOptions,
            })}
          />
        )}

        {!!description && isElementDescription(description) && description}
      </BoxArticle>

      {links.length > 0 && (
        <div className={styles.buttons(type)}>
          {links.slice(0, MAX_NUMBER_OF_BUTTONS).map((link, index) =>
            isLotteryFormButton(link) ? (
              // TODO: Create key generator
              <LotteryFormButton key={link.ctaText} {...link} />
            ) : (
              // TODO: Create key generator
              <CustomLink key={link.text} {...link}>
                <NormalButton
                  size="M"
                  {...buttonProps(index, pageTheme)}
                  className={
                    pageTheme === 'eair' && theme === BoxTheme.Secondary
                      ? styles.secondaryThemeButtonEAIR
                      : ''
                  }
                  text={link.text}
                />
              </CustomLink>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default BoxContent;
