import { css } from '@emotion/css';
import cls from 'classnames';
import { useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import NormalButton from '@/components/button/normal';
import CarouselNavButton from '@/components/carousel-nav-button';
import content from '@/components/lottery-form/modals/content';
import Modal from '@/components/modal';
import useModal from '@/components/modal/use-modal';
import { PrizeItems } from '@/types/apis';

import MobileTicketCard from '../mobile-ticket-card';

const swiperClass = css`
  .swiper-slide {
    display: flex;
    justify-content: center;
    transition: all 200ms linear;

    &.swiper-slide-active {
      transform: scale(1);
    }

    &.swiper-slide-prev {
      transform: translateX(10%) scale(0.8);
    }

    &.swiper-slide-next {
      transform: translateX(-10%) scale(0.8);
    }
  }
`;

const INITIAL_TICKET_POSITION = 1;

interface MobileTicketModalProps {
  image: string;
  title: string;
  tickets: PrizeItems[];
  modus: string;
  details: string;
  amount: number;
  status: string;
  categories: string;
  isOpen: boolean;
  closeModal: () => void;
}

const MobileTicketModal = ({
  image,
  title,
  status,
  categories,
  tickets,
  modus,
  details,
  isOpen,
  closeModal,
  amount,
}: MobileTicketModalProps) => {
  const { isModalOpen, setIsModalOpen } = useModal(false);
  const [mainSwiper, setMainSwiper] = useState<SwiperCore | null>(null);
  const [activeTicketPosition, setActiveTicketPosition] = useState<number>(
    INITIAL_TICKET_POSITION,
  );

  const updateTicketPosition = () => {
    if (mainSwiper) {
      setActiveTicketPosition(mainSwiper?.activeIndex + 1);
    }
  };

  const openDetails = () => setIsModalOpen(true);
  const closeDetails = () => setIsModalOpen(false);

  return (
    <>
      {isOpen && (
        <Modal onClose={closeModal} showCloseIcon={false}>
          <div className="w-full h-full flex flex-col items-center justify-between">
            <div className="w-full h-full">
              <div className="flex flex-col justify-center h-full mt-4">
                <Swiper
                  wrapperTag="div"
                  slidesPerView={1.35}
                  centeredSlides={true}
                  observer
                  onSwiper={setMainSwiper}
                  className={swiperClass}
                  style={{ margin: 0, width: '100%' }}
                  onSlideNextTransitionStart={() => updateTicketPosition()}
                  onSlidePrevTransitionStart={() => updateTicketPosition()}
                >
                  {tickets.map((ticket, index) => (
                    <SwiperSlide tag="div" key={ticket.id}>
                      <MobileTicketCard
                        image={image}
                        title={title}
                        barcode={ticket.barcode}
                        qrCode={ticket.qrCode}
                        modus={modus}
                        status={ticket.wasScanned}
                        categories={categories}
                        openDetails={openDetails}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="flex justify-center items-center gap-8 mt-8">
                  <div
                    onClick={() => {
                      mainSwiper?.slidePrev();
                    }}
                  >
                    <CarouselNavButton
                      type="prev"
                      smSize="small"
                      mdSize="medium"
                      className={cls(
                        mainSwiper?.isBeginning &&
                          'text-gray-1 bg-gray-2 cursor-none',
                      )}
                    />
                  </div>

                  <div>
                    <span>{`${activeTicketPosition}/${amount}`}</span>
                  </div>

                  <div
                    onClick={() => {
                      mainSwiper?.slideNext();
                    }}
                  >
                    <CarouselNavButton
                      type="next"
                      smSize="small"
                      mdSize="medium"
                      className={cls(
                        mainSwiper?.isEnd &&
                          'text-gray-1 bg-gray-2 cursor-none',
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-8">
              <NormalButton
                className="mt-auto md:mt-8 bg-white text-black hover:bg-red-light-darker"
                text="Schliessen"
                onClick={closeModal}
              />
            </div>
          </div>
        </Modal>
      )}

      {isModalOpen && (
        <Modal onClose={closeDetails} showCloseIcon={false}>
          <div className="w-full h-full flex flex-col justify-between px-5 pt-20 pb-8">
            <div
              className="overflow-auto"
              dangerouslySetInnerHTML={{ __html: details }}
            ></div>

            <NormalButton
              className="mt-8 bg-white text-black hover:bg-red-light-darker"
              text="Schliessen"
              onClick={closeDetails}
            />
          </div>
        </Modal>
      )}

      <div onClick={openDetails}>{content}</div>
    </>
  );
};

export default MobileTicketModal;
