import cls from 'classnames';
import { FC } from 'react';

import { BoxTheme, BoxType } from '@/types/cms';
import { ImageAsset } from '@/types/views/generic';

import Image from '../image';
import LargeBox from './components/large-box';
import NormalBox from './components/normal-box';
import styles from './styles';

export interface BoxProps {
  type: BoxType;
  theme: BoxTheme;
  image?: Omit<ImageAsset, 'contentType' | 'id' | 'width' | 'height'>;
  imagePlaceholderClass?: string;
  className?: string;
}

const Box: FC<BoxProps> = ({
  image,
  type,
  theme,
  children,
  className,
  imagePlaceholderClass,
}) => {
  const commonProps = {
    imageElement: image && (
      <Image
        src={image.src}
        alt={image.description}
        objectFit="cover"
        className="aspect aspect-4-3"
      />
    ),
    className: cls(className, styles.wrapper(theme)),
    imagePlaceholderClass,
  };

  switch (type) {
    case BoxType.LargeImgLeft:
      return (
        <LargeBox imageSide="left" {...commonProps}>
          {children}
        </LargeBox>
      );

    case BoxType.LargeImgRight:
      return (
        <LargeBox imageSide="right" {...commonProps}>
          {children}
        </LargeBox>
      );

    case BoxType.Medium:
    case BoxType.Small:
    case BoxType.Contact:
      return (
        <NormalBox boxType={type} {...commonProps}>
          {children}
        </NormalBox>
      );

    default: {
      console.warn(
        'Wrong box type passed to Box component, returning LargeBox',
      );

      return (
        <LargeBox {...commonProps} imageSide="left">
          {children}
        </LargeBox>
      );
    }
  }
};

export default Box;
