import { useRouter } from 'next/router';
import { FC, RefCallback } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import MultipleTagsChips from '@/components/multiple-tags-chips';
import { ContentFeedEntryMeme } from '@/types/views/generic';

import styles from './styles';

type MemeTeaserProps = Omit<ContentFeedEntryMeme, 'id' | 'type'> & {
  imageWrapperRef: RefCallback<HTMLDivElement>;
};

const MemeTeaser: FC<MemeTeaserProps> = ({
  image,
  description,
  link,
  tags,
  imageWrapperRef,
}) => {
  const router = useRouter();

  const selectedFilters = router.query?.filters;
  const urlWithFilters = `${link.href}${
    selectedFilters ? `?filters=${selectedFilters}` : ''
  }`;

  const linkWithFilters = {
    ...link,
    href: urlWithFilters,
    as: urlWithFilters,
  };

  return (
    <article className={styles.wrapper}>
      <CustomLink {...linkWithFilters} className={styles.imageWrapper}>
        <div ref={imageWrapperRef} className="h-full w-full">
          <Image src={image} alt={description} className="aspect aspect-1-1" />
        </div>
      </CustomLink>

      {!!tags?.length && (
        <div className={styles.descriptionWrapper}>
          <MultipleTagsChips tags={tags} />
        </div>
      )}
    </article>
  );
};

export default MemeTeaser;
