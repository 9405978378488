import { useRouter } from 'next/router';
import * as R from 'ramda';
import { FC, memo, RefCallback, useRef } from 'react';

import CustomLink from '@/components/custom-link';
import PlayIconBigWithBorder from '@/components/icons/play-icon-big-with-border';
import Image from '@/components/image';
import MultipleTagsChips from '@/components/multiple-tags-chips';
import { ContentFeedEntryVideo } from '@/types/views/generic';

import styles from './styles';

interface VideoTeaserProps extends ContentFeedEntryVideo {
  imageWrapperRef: RefCallback<HTMLDivElement>;
}

const VideoTeaser: FC<VideoTeaserProps> = memo(
  ({
    title,
    contentCategories,
    link,
    tags,
    videoPreviewImage,
    videoPreviewVideo,
    imageWrapperRef,
    hasInvertedThemeColor = false,
  }) => {
    const previewVideo = useRef<HTMLVideoElement | null>(null);
    const router = useRouter();

    const selectedFilters = router.query?.filters;
    const urlWithFilters = `${link.href}${
      selectedFilters ? `?filters=${selectedFilters}` : ''
    }`;

    const linkWithFilters = {
      ...link,
      href: urlWithFilters,
      as: urlWithFilters,
    };

    return (
      <div>
        <CustomLink {...linkWithFilters} className="group">
          <div className={styles.previewWrapper} ref={imageWrapperRef}>
            {videoPreviewVideo ? (
              <div className="aspect aspect-3-4">
                <video
                  ref={previewVideo}
                  className={styles.previewVideo}
                  loop
                  muted
                  preload="auto"
                  poster={videoPreviewImage}
                  playsInline
                  autoPlay
                >
                  <source src={videoPreviewVideo} type="video/mp4" />
                  <p>Videos are not supported in your browser.</p>
                </video>
              </div>
            ) : (
              <Image
                basicLoader
                src={videoPreviewImage as string}
                alt={title}
                className="aspect aspect-3-4"
                objectFit="cover"
              />
            )}

            <div className="absolute top-0 left-0 w-full h-full rounded-2 md:rounded-4 bg-black bg-opacity-70 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
              <PlayIconBigWithBorder
                backgroundColor="transparent"
                className="w-1/3 h-1/3"
              />
            </div>
          </div>

          {R.isEmpty(contentCategories) && (
            <div className={styles.category}>{contentCategories[0]}</div>
          )}

          <div className={styles.title(hasInvertedThemeColor)} title={title}>
            {title}
          </div>
        </CustomLink>

        {!!tags.length && (
          <div className="mt-2 md:mt-4">
            <MultipleTagsChips
              tags={tags}
              hasInvertedThemeColor={hasInvertedThemeColor}
            />
          </div>
        )}
      </div>
    );
  },
);

export default VideoTeaser;
