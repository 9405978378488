import { BoxTheme, BoxType } from '@/types/cms';

const getOverlineTextClasses = (theme: BoxTheme) =>
  theme === BoxTheme.Default ? 'text-gray-3' : 'text-gray-1';

const getButtonsFlexClasses = (type: BoxType) =>
  [BoxType.LargeImgLeft, BoxType.LargeImgRight].includes(type)
    ? 'md:flex-row'
    : '';

const styles = {
  overlineText: (theme: BoxTheme) =>
    `text-overline teair:text-air-lila tesn:text-esn-secondary-light teds:text-eds-red-medium tels:text-els-gold-light ${getOverlineTextClasses(
      theme,
    )}`,
  buttons: (type: BoxType) =>
    `flex flex-col flex-wrap gap-4 ${getButtonsFlexClasses(type)}`,
  secondaryThemePrimaryButton: 'text-pink bg-black-light hover:bg-black',
  secondaryThemeSecondaryButton:
    'text-black-light bg-pink hover:bg-pink-lighter border-black-light border-2',
  secondaryThemeButtonEAIR: 'text-air-lila-light',
};

export default styles;
