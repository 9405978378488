import { UserProfileResponse } from '@/api/types';

export const requiredFieldsVariableNames = {
  email: 'quiz-incomplete-email',
  firstName: 'quiz-incomplete-firstname',
  lastName: 'quiz-incomplete-lastname',
  mobileNumber: 'quiz-incomplete-mobile-number',
  birthdate: 'quiz-incomplete-birth-date',
  gender: 'quiz-incomplete-gender',
  zipCode: 'quiz-incomplete-zip-code',
  city: 'quiz-incomplete-city',
  addressLine1: 'quiz-incomplete-address',
};

type incompleteProfileReturn =
  | (
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'mobileNumber'
      | 'birthdate'
      | 'gender'
      | 'zipCode'
      | 'city'
      | 'addressLine1'
    )[];

const getIncompleteProfileFields = (
  userProfile: UserProfileResponse | null,
): incompleteProfileReturn => {
  if (!userProfile) {
    return [];
  }

  const profileRequiredFields = [
    'email',
    'firstName',
    'lastName',
    'mobileNumber',
    'birthdate',
    'gender',
  ] as const;

  const incompleteProfileRequiredFields = profileRequiredFields.filter(
    (fieldName) => !userProfile?.[fieldName],
  );

  const addressRequiredFields = ['zipCode', 'city', 'addressLine1'] as const;

  const incompleteAddressRequiredFields = addressRequiredFields.filter(
    (fieldName) => !userProfile?.energyAddress?.[fieldName],
  );

  return [
    ...incompleteProfileRequiredFields,
    ...incompleteAddressRequiredFields,
  ];
};

export default getIncompleteProfileFields;
