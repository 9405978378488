import { FC, memo, RefCallback, useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import MultipleTagsChips from '@/components/multiple-tags-chips';
import PlayPauseButtonTransparent from '@/components/play-pause-button-transparent';
import Tooltip from '@/components/tooltip';
import getChannel from '@/lib/get-channel';
import getCurrentShow from '@/lib/get-current-show';
import { selectPlayerChannelGroups } from '@/store/slices/player';
import { ContentFeedEntryStation, ShowEntry } from '@/types/views/generic';

type StationTeaserProps = Omit<ContentFeedEntryStation, 'id' | 'type'> & {
  imageWrapperRef: RefCallback<HTMLDivElement>;
};

const StationTeaser: FC<StationTeaserProps> = memo(
  ({ stationId, title, link, image, imageWrapperRef }) => {
    const channelGroups = useSelector(selectPlayerChannelGroups);
    const [showData, setShowData] = useState<ShowEntry | null>(null);

    const { ref: inViewRef, inView } = useInView({
      threshold: 0,
      triggerOnce: true,
      rootMargin: '75% 0px 0px',
    });

    const fetchCurrentShow = useCallback(async () => {
      const currentShow = await getCurrentShow(stationId);

      if (currentShow) {
        setShowData(currentShow);
      }
    }, [stationId]);

    useEffect(() => {
      if (inView && !showData) {
        fetchCurrentShow();
      }
    }, [inView, fetchCurrentShow, showData]);

    // TODO (https://energych.atlassian.net/browse/ECH-4140): Bring this back in optimized way
    // useEffect(() => {
    //   if (showData) {
    //     const currentDate = new Date();
    //     const showEndDate = new Date(showData.to);
    //     const timeToEndShow = Math.abs(
    //       showEndDate.getTime() - currentDate.getTime(),
    //     );
    //
    //     const timeout = setTimeout(() => {
    //       fetchCurrentShow();
    //     }, timeToEndShow);
    //
    //     return () => clearTimeout(timeout);
    //   }
    //
    //   return () => null;
    // }, [fetchCurrentShow, title, showData, showData?.to]);

    const station = getChannel(channelGroups, stationId);

    return (
      <div className="flex flex-col items-center" ref={imageWrapperRef}>
        <div ref={inViewRef} />

        <div className="overflow-hidden w-full rounded-full relative">
          <Image
            src={station?.logoUrl ? station.logoUrl : image.src}
            alt={station ? station.seoDescription : image.description}
            objectFit="cover"
            className="aspect aspect-1-1"
          />

          <div className="absolute inset-0">
            <PlayPauseButtonTransparent channelId={stationId} />
          </div>
        </div>

        <div className="text-center mt-4">
          <CustomLink {...link} className="w-full">
            <h1 className="text-overline text-gray-3">{title}</h1>

            {showData?.title && (
              <Tooltip
                text={showData.title}
                componentClassName="text-body1 md:font-semibold line-clamp-1 mt-1"
                componentType="h2"
              >
                {showData.title}
              </Tooltip>
            )}
          </CustomLink>
        </div>

        {!!showData?.moderatorTags.length && (
          <div className="flex flex-col items-center gap-2 md:gap-4 mt-4 max-w-full overflow-hidden">
            <MultipleTagsChips tags={showData.moderatorTags} />
          </div>
        )}
      </div>
    );
  },
);

export default StationTeaser;
