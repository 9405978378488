import { FC, RefCallback } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import Skeleton from '@/components/skeleton';
import Tooltip from '@/components/tooltip';
import { ChannelPodcastTeaser as ChannelPodcastTeaserT } from '@/types/views/generic';

import styles from './styles';

interface PodcastTeaserProps extends ChannelPodcastTeaserT {
  imageWrapperRef: RefCallback<HTMLDivElement>;
}

const ChannelPodcastTeaser: FC<PodcastTeaserProps> = ({
  title,
  description,
  imageWrapperRef,
  imageUrl,
  link,
}) => (
  <CustomLink {...link}>
    <div className={styles.imageWrapper} ref={imageWrapperRef}>
      {imageUrl ? (
        <Image src={imageUrl} alt={title} className="aspect aspect-1-1" />
      ) : (
        <Skeleton className="aspect aspect-1-1" />
      )}
    </div>

    <h2 className={styles.title}>{title}</h2>

    {description && (
      <div className={styles.descriptionWrapper}>
        <Tooltip
          text={description}
          componentClassName={styles.description}
          componentType="span"
        >
          {description}
        </Tooltip>
      </div>
    )}
  </CustomLink>
);

export default ChannelPodcastTeaser;
