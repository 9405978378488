import { css } from '@emotion/css';

import { ImageSide } from '.';

const imageWrapperSizeClass = css`
  min-width: 40%;
`;

const flexDirectionClassesByImageSide: Record<ImageSide, string> = {
  left: 'md:flex-row',
  right: 'md:flex-row-reverse',
};

const styles = {
  wrapper: (imageSide: ImageSide) =>
    `flex flex-col overflow-hidden rounded-2 md:rounded-4 ${flexDirectionClassesByImageSide[imageSide]}`,
  imageWrapper: `relative ${imageWrapperSizeClass}`,
  boxContent: 'p-4 md:p-6 xl:px-12 2xl:p-14 flex items-center w-full',
};

export default styles;
