import cls from 'classnames';
import { FC, ReactElement } from 'react';

import { BoxType } from '@/types/cms';

import styles from './styles';

interface NormalBoxProps {
  imageElement?: ReactElement;
  className?: string;
  imagePlaceholderClass?: string;
  boxType?: BoxType;
}

const NormalBox: FC<NormalBoxProps> = ({
  imageElement,
  children,
  className = '',
  imagePlaceholderClass = '',
  boxType,
}) => (
  <article className={cls(styles.wrapper, className)}>
    <div className={cls(styles.imageWrapper, imagePlaceholderClass)}>
      {imageElement}
    </div>

    <div
      className={
        boxType === BoxType.Contact
          ? 'pt-6 px-5 pb-8 md:px-6 3xl:px-10'
          : styles.boxContent
      }
    >
      {children}
    </div>
  </article>
);

export default NormalBox;
