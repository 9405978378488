import cls from 'classnames';

import { TeaserGroup } from '@/types/views/generic';

const commonClasses = (isMobile: boolean, hasInvertedThemeColor: boolean) =>
  cls(
    'absolute z-10 top-0 bottom-0 pointer-events-none md:pb-0 from-black',
    hasInvertedThemeColor ? 'teair:from-air-deepBlue' : 'teair:from-white',
    isMobile ? 'w-12' : 'w-36',
  );

const teaserGroupSizes: Record<TeaserGroup, string> = {
  [TeaserGroup.Default]: 'w-5/6',
  [TeaserGroup.PodcastsCreatorsChannels]: 'w-36',
  [TeaserGroup.Memes]: 'w-5/6',
  [TeaserGroup.Categories]: 'w-36',
  [TeaserGroup.Videos]: 'w-5/6',
  [TeaserGroup.Stations]: 'w-36',
  [TeaserGroup.Prizes]: 'w-5/6',
  [TeaserGroup.Contact]: 'w-36',
};

const moreSlidesButton: Record<TeaserGroup, string> = {
  [TeaserGroup.Videos]: 'rounded-2 md:rounded-4',
  [TeaserGroup.Memes]: 'rounded-2',
  [TeaserGroup.Default]: 'rounded-2',
  [TeaserGroup.Categories]: 'rounded-full',
  [TeaserGroup.PodcastsCreatorsChannels]: 'rounded-full',
  [TeaserGroup.Stations]: 'rounded-full',
  [TeaserGroup.Prizes]: 'rounded-4',
  [TeaserGroup.Contact]: 'rounded-full',
};

const moreSlidesAspect: Record<TeaserGroup, string> = {
  [TeaserGroup.Videos]: 'aspect-3-4',
  [TeaserGroup.Default]: 'aspect-16-9',
  [TeaserGroup.Memes]: 'aspect-1-1',
  [TeaserGroup.Categories]: 'aspect-1-1',
  [TeaserGroup.PodcastsCreatorsChannels]: 'aspect-1-1',
  [TeaserGroup.Stations]: 'aspect-1-1',
  [TeaserGroup.Prizes]: 'aspect-1-1',
  [TeaserGroup.Contact]: 'aspect-1-1',
};

const styles = {
  swiper: 'overflow-visible md:overflow-hidden w-full',
  slide: (isMobile: boolean, teaserGroup: TeaserGroup) =>
    isMobile ? teaserGroupSizes[teaserGroup] : '',
  leftGradient: (isMobile: boolean, hasInvertedThemeColor: boolean) =>
    cls(
      commonClasses(isMobile, hasInvertedThemeColor),
      'bg-gradient-to-r',
      isMobile ? '-left-5' : 'left-0',
    ),
  rightGradient: (isMobile: boolean, hasInvertedThemeColor: boolean) =>
    cls(
      commonClasses(isMobile, hasInvertedThemeColor),
      'bg-gradient-to-l',
      isMobile ? '-right-5' : 'right-0',
    ),
  loadMoreSlide: (teaserGroup: TeaserGroup) =>
    cls(
      'flex flex-col items-start bg-black-light',
      moreSlidesButton[teaserGroup],
    ),
  loadMoreSlideWrapper: (teaserGroup: TeaserGroup) =>
    cls(
      'overflow-hidden w-full relative cursor-pointer aspect',
      moreSlidesAspect[teaserGroup],
    ),
  loadMoreSlideContent: 'p-4 h-auto flex justify-center items-center',
};

export default styles;
