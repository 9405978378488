import { FC } from 'react';

interface DownloadIconProps {
  fill?: string;
  className?: string;
}

export const DownloadIcon: FC<DownloadIconProps> = ({
  fill = 'white',
  className = '',
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19 21.7C19 21.8657 18.8657 22 18.7 22H5.3C5.13431 22 5 21.8657 5 21.7V20.3C5 20.1343 5.13431 20 5.3 20H18.7C18.8657 20 19 20.1343 19 20.3V21.7ZM12.2121 17.7879C12.095 17.905 11.905 17.905 11.7879 17.7879L6.21213 12.2121C6.09497 12.095 6.09497 11.905 6.21213 11.7879L7.19816 10.8018C7.31521 10.6848 7.50493 10.6847 7.62213 10.8015L10.4882 13.6596C10.6773 13.8482 11 13.7142 11 13.4472V2.3C11 2.13431 11.1343 2 11.3 2H12.7C12.8657 2 13 2.13431 13 2.3V13.4472C13 13.7142 13.3227 13.8482 13.5118 13.6596L16.3779 10.8015C16.4951 10.6847 16.6848 10.6848 16.8018 10.8018L17.7879 11.7879C17.905 11.905 17.905 12.095 17.7879 12.2121L12.2121 17.7879Z"
      fill={fill}
    />
  </svg>
);

export default DownloadIcon;
