import { FC } from 'react';

import SectionAds from '@/components/advertisement/components/section-ads';
import renderComponent from '@/lib/render-component';
import { ContentFeedEntryEntryType } from '@/types/views/generic';

import ContactItem from '../../contact-component/contact-item';
import CategoryTeaser from '../components/teasers/category';
import ChannelPodcastTeaser from '../components/teasers/channel-podcast';
import CreatorTeaser from '../components/teasers/creator';
import MemeTeaser from '../components/teasers/meme';
import ShowFormatProfileTeaser from '../components/teasers/show-format-profile';
import SoundpieceTeaser from '../components/teasers/soundpiece';
import StationTeaser from '../components/teasers/station';
import TicketTeaser from '../components/teasers/ticket';
import VideoTeaser from '../components/teasers/video';

const teaserComponents: Record<
  ContentFeedEntryEntryType,
  // TODO: remove undefined later when all undefined components are ready!
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FC<any> | undefined
> = {
  video: VideoTeaser,
  meme: MemeTeaser,
  category: CategoryTeaser,
  station: StationTeaser,
  soundpiece: SoundpieceTeaser,
  creator: CreatorTeaser,
  channel: ChannelPodcastTeaser,
  podcast: ChannelPodcastTeaser,
  show: ShowFormatProfileTeaser,
  format: ShowFormatProfileTeaser,
  profile: ShowFormatProfileTeaser,
  cardTeaser: undefined,
  contentCategory: undefined,
  mediumRectangleAd: SectionAds.MediumRectangleAd,
  mobileMediumRectangleAd: SectionAds.MobileMediumRectangleAd,
  prize: TicketTeaser,
  contactItem: ContactItem,
};

const renderEntryTeaser = renderComponent(teaserComponents);

export default renderEntryTeaser;
