import cls from 'classnames';
import { FC } from 'react';

import { BoxTheme, BoxType } from '@/types/cms';

import styles from './styles';

interface BoxArticleProps {
  title: string;
  theme: BoxTheme;
  type: BoxType;
  className?: string;
  textClassNames?: string;
}

const BoxArticle: FC<BoxArticleProps> = ({
  title,
  children,
  theme,
  type,
  className = '',
  textClassNames,
}) => (
  <div className={className}>
    {type !== BoxType.Contact && (
      <h3 className={cls(styles.title(theme, type), textClassNames)}>
        {title}
      </h3>
    )}

    {children && (
      <div className={cls(styles.content(theme, type), textClassNames)}>
        {children}
      </div>
    )}
  </div>
);

export default BoxArticle;
