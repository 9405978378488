import { css } from '@emotion/css';
import cls from 'classnames';

import TailwindTheme from '@/../tailwind/theme';

const { colors } = TailwindTheme;

const audioBubbleButton = (borderWidth: number) => css`
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: ${borderWidth}px solid ${colors.gray['1']};
`;

const audioBubbleProgress = css`
  top: -6px;
  left: -6px;
  width: calc(100% + 12px);
`;

const audioBubblePlayPause = css`
  transform: translate(-50%, -50%);
`;

const circle = (isActive: boolean) => css`
  transition: stroke-dashoffset 0.25s;
  stroke: ${isActive && colors.red.medium};
`;

const styles = {
  audioBubble: 'bg-black-light rounded-full relative',
  audioBubbleButton: (borderWidth: number) =>
    cls(
      audioBubbleButton(borderWidth),
      'w-full h-full relative p-0 cursor-pointer rounded-full outline-none',
    ),
  audioBubbleImage: 'rounded-full w-full h-full object-cover',
  audioBubbleProgress: cls('absolute', audioBubbleProgress),
  audioBubblePlayPause: cls(
    'absolute top-1/2 left-1/2 h-4 w-3',
    audioBubblePlayPause,
  ),
  circle: (isActive: boolean) => circle(isActive),
};

export default styles;
