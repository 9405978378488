import { Document, INLINES } from '@contentful/rich-text-types';
import React, { FC, ReactNode } from 'react';

import RichTextRenderer, {
  renderInlineHyperlink,
} from '@/components/rich-text-renderer';

const ModalContent: FC<{
  image?: ReactNode;
  title: ReactNode | null;
  text: Document | null;
}> = ({ image, title, text }) => (
  <div className="flex flex-col items-center text-center">
    {image && <div className="w-full mb-8">{image}</div>}

    {title && <h1 className="mb-2 text-pink text-h4">{title}</h1>}

    {text && (
      <RichTextRenderer
        className="text-body1"
        body={text}
        options={{
          renderNode: {
            [INLINES.HYPERLINK]: renderInlineHyperlink('text-body1'),
          },
        }}
      />
    )}
  </div>
);

export default ModalContent;
