import cls from 'classnames';

import { BoxTheme } from '@/types/cms';

const styles = {
  wrapper: (theme: BoxTheme) =>
    cls(
      'tels:bg-els-blue-grey',
      theme === BoxTheme.Default
        ? 'bg-black-light teair:bg-air-deepBlue tesn:bg-esn-secondary-dark'
        : 'bg-pink teair:bg-air-lila-light tesn:bg-esn-secondary',
    ),
};

export default styles;
