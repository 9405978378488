import cls from 'classnames';
import { FC, ReactElement } from 'react';

import styles from './styles';

export type ImageSide = 'left' | 'right';

export interface LargeBoxProps {
  imageElement?: ReactElement;
  imageSide?: ImageSide;
  imagePlaceholderClass?: string;
  className?: string;
}

const LargeBox: FC<LargeBoxProps> = ({
  imageElement,
  imageSide = 'left',
  imagePlaceholderClass = '',
  children,
  className = '',
}) => (
  <article className={cls(className, styles.wrapper(imageSide))}>
    <div className={cls(styles.imageWrapper, imagePlaceholderClass)}>
      {imageElement}
    </div>

    <div className={styles.boxContent}>{children}</div>
  </article>
);

export default LargeBox;
