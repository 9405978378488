import React, { FC, RefCallback } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import { ContentFeedEntryCategory } from '@/types/views/generic';

import styles from './styles';

interface CategoryTeaserProps extends ContentFeedEntryCategory {
  imageWrapperRef: RefCallback<HTMLDivElement>;
}

const CategoryTeaser: FC<CategoryTeaserProps> = ({
  image,
  title,
  link,
  imageWrapperRef,
}) => (
  <CustomLink {...link} className="group">
    <div className={styles.iconBackground}>
      <div className={styles.iconFlexWrapper} ref={imageWrapperRef}>
        <div className={styles.iconWidthWrapper}>
          <Image src={image.src} />
        </div>
      </div>
    </div>
    <h3 className={styles.title}>{title}</h3>
  </CustomLink>
);

export default CategoryTeaser;
