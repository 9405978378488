import { css } from '@emotion/css';

type CssPosition = 'left' | 'right';

const zIndex29 = css`
  z-index: 29;
`;
const topNavbarHeight = '8rem';
const rightSideBarHeight = css`
  height: calc(100vh - ${topNavbarHeight});
`;

const styles = {
  commonAdBox: 'md:content-box flex justify-center',
  sideAdTopContainer: (position: CssPosition) =>
    `${
      position === 'left' ? 'side-ad-box-left' : 'side-ad-box-right'
    } sticky top-28 right-0 h-full ${zIndex29} ${rightSideBarHeight}`,
  sideAdBottomContainer:
    'sticky top-0 right-0 flex justify-center h-full w-full',
};

export default styles;
