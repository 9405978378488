import cls from 'classnames';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePlayer from '@/lib/hooks/use-player';
import {
  selectPlayerCurrentChannel,
  selectPlayerIsLoading,
  selectPlayerIsPlaying,
  setCurrentChannelId,
} from '@/store/slices/player';
import { Channel } from '@/types/views/generic';

import LoadingIcon from '../icons/loading-icon';
import PauseIconBigWithBorder from '../icons/pause-icon-big-with-border';
import PlayIconBigWithBorder from '../icons/play-icon-big-with-border';

interface PlayPauseButtonTransparentProps {
  channelId: Channel['id'];
  className?: string;
}

const buttonClasses =
  'inline-flex absolute w-2/5 h-2/5 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2';

const PlayPauseButtonTransparent: FC<PlayPauseButtonTransparentProps> = ({
  channelId,
  className,
}) => {
  const isPlayerLoading = useSelector(selectPlayerIsLoading);
  const isPlaying = useSelector(selectPlayerIsPlaying);
  const currentPlayerChannel = useSelector(selectPlayerCurrentChannel);

  const player = usePlayer();
  const dispatch = useDispatch();

  const isCurrentChannel = currentPlayerChannel?.id === channelId;
  const isCurrentChannelPlaying = isPlaying && isCurrentChannel;

  const playOrStopChannel = () => {
    if (isPlayerLoading) return;

    if (isPlaying) {
      if (isCurrentChannel) {
        player?.stop();
      } else {
        dispatch(setCurrentChannelId(channelId));
      }
    } else {
      if (!isCurrentChannel) {
        dispatch(setCurrentChannelId(channelId));
      }
      player?.play();
    }
  };

  return (
    <div
      className={cls(
        'w-full h-full bg-black cursor-pointer relative',
        'bg-opacity-0 hover:bg-opacity-70 transition-opacityWithBg duration-200',
        isPlayerLoading &&
          isCurrentChannel &&
          'opacity-100 bg-opacity-30 hover:bg-opacity-70',
        className,
      )}
      onClick={playOrStopChannel}
    >
      {isCurrentChannelPlaying ? (
        <PauseIconBigWithBorder className={buttonClasses} />
      ) : (
        <>
          {isPlayerLoading && isCurrentChannel ? (
            <div className="flex justify-center items-center h-full">
              <LoadingIcon className="w-2/5 h-2/5" />
            </div>
          ) : (
            <PlayIconBigWithBorder className={buttonClasses} />
          )}
        </>
      )}
    </div>
  );
};

export default PlayPauseButtonTransparent;
