import cls from 'classnames';

import { BoxTheme, BoxType } from '@/types/cms';

const getTitleColorClasses = (theme: BoxTheme) =>
  theme === BoxTheme.Default
    ? 'text-pink teair:text-white tesn:text-white teds:text-white tels:text-white'
    : 'text-black teair:text-air-deepBlue tesn:text-white teds:text-white tels:text-white';

const getDescriptionColorClasses = (theme: BoxTheme) =>
  theme === BoxTheme.Default
    ? 'text-white'
    : 'text-black teair:text-air-deepBlue tesn:text-white teds:text-white tels:text-white';

const getTitleSizeClasses = (type: BoxType) =>
  [BoxType.LargeImgLeft, BoxType.LargeImgRight].includes(type)
    ? 'md:text-h4'
    : 'md:text-h5';

const styles = {
  wrapper: '',
  title: (theme: BoxTheme, type: BoxType) =>
    `text-h4 ${cls(getTitleColorClasses(theme), getTitleSizeClasses(type))}`,
  content: (theme: BoxTheme, type: BoxType) =>
    cls(
      'text-body2',
      type !== BoxType.Contact && 'mt-4',
      cls(getDescriptionColorClasses(theme)),
    ),
};

export default styles;
