// TODO: use polyfill only when ResizeObserver not supported natively
import { ResizeObserver } from '@juggle/resize-observer';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback?: (entry: DOMRectReadOnly) => void,
) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback],
  );

  useEffect(() => {
    if (!resizeObserverRef.current) {
      resizeObserverRef.current = new ResizeObserver((entries) =>
        handleResize(entries),
      );
    }

    const resizeObserver = resizeObserverRef.current;

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, handleResize]);

  return [width, height];
};

export default useResizeObserver;
