import cls from 'classnames';
import { FC } from 'react';

import Image from '@/components/image';

import { ContactItemProps } from '..';

const ContactItem: FC<ContactItemProps> = ({
  text,
  email,
  icon,
  iconSelected,
  isSelected,
  onContactClick,
}) => {
  const focusIcon = iconSelected ?? icon;

  return (
    <div
      className="flex flex-col items-center group cursor-pointer"
      onClick={() => onContactClick(email)}
    >
      <div
        className={cls(
          'aspect aspect-1-1 w-full h-full rounded-full overflow-hidden',
          'flex flex-row justify-center items-center',
          isSelected ? 'bg-white' : 'bg-black-light',
        )}
      >
        <div className="w-1/2 h-1/2 m-auto">
          <Image
            className="block group-hover:hidden"
            src={isSelected ? focusIcon : icon}
            basicLoader
            objectFit="cover"
          />
          <Image
            className="hidden group-hover:block"
            src={focusIcon}
            basicLoader
            objectFit="cover"
          />
        </div>
      </div>

      <h3 className="mt-4 text-body2 text-center">{text}</h3>

      <p
        className={cls(
          'underline text-overline font-semibold text-center ',
          isSelected ? 'text-red' : 'text-red-medium group-hover:text-red',
        )}
      >
        {isSelected ? 'Ausgewählt' : 'Auswählen'}
      </p>
    </div>
  );
};

export default ContactItem;
