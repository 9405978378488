import cls from 'classnames';

// Duplicated rounding styles are for safari
const roundedClasses = 'rounded-2 md:rounded-4';

const styles = {
  previewWrapper: `relative ${roundedClasses} overflow-hidden object-cover bg-black-light`,
  previewVideo: `object-cover ${roundedClasses}`,
  category: 'mt-2 2xl:mt-4 text-overline text-gray-3',
  title: (hasInvertedThemeColor: boolean) =>
    cls(
      'mt-1 2xl:mt-4 text-body2 md:text-body1 font-semibold line-clamp-3 md:line-clamp-2',
      hasInvertedThemeColor ? 'teair:text-white' : 'teair:text-air-deepBlue',
    ),
};

export default styles;
