import React, { FC, RefCallback } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import { ShowFormatProfileTeaser as ShowFormatProfileTeaserType } from '@/types/views/generic';

import styles from './styles';

interface ShowFormatProfileTeaserProps extends ShowFormatProfileTeaserType {
  imageWrapperRef: RefCallback<HTMLDivElement>;
}

const ShowFormatProfileTeaser: FC<ShowFormatProfileTeaserProps> = ({
  title,
  image,
  link,
  imageWrapperRef,
}) => (
  <CustomLink {...link}>
    <article>
      <div className={styles.imageWrapper} ref={imageWrapperRef}>
        <Image
          src={image.src}
          alt={image.description}
          objectFit="cover"
          className="aspect aspect-16-9"
        />
      </div>

      <span className={styles.title}>{title}</span>
    </article>
  </CustomLink>
);

export default ShowFormatProfileTeaser;
