import cls from 'classnames';
import { FC } from 'react';

import BoxContent from '@/components/box/components/box-content';
import { BoxType } from '@/types/cms';
import { EntryComponentProps } from '@/types/views/generic';
import { BoxSectionEntry } from '@/types/views/sections';

import Box from '../../box';

const widthClassesByBoxType: Record<BoxType, string> = {
  [BoxType.Small]: 'md:w-1/3',
  [BoxType.Contact]: 'md:w-1/3',
  [BoxType.Medium]: 'md:w-1/2',
  [BoxType.LargeImgLeft]: 'md:w-full',
  [BoxType.LargeImgRight]: 'md:w-full',
};

const BoxSection: FC<EntryComponentProps<BoxSectionEntry>> = ({
  boxTitle,
  title,
  image,
  description,
  overlineText,
  links,
  type,
  theme,
  imagePlaceholderClass = '',
  deactivateAdSlots,
}) => (
  <div className={cls('content-box', deactivateAdSlots && 'ml-auto')}>
    {boxTitle && (
      <h2 className="text-h2 text-pink tesn:text-white teair:text-air-deepBlue mb-6">
        {boxTitle}
      </h2>
    )}

    <Box
      image={image}
      imagePlaceholderClass={imagePlaceholderClass}
      type={type}
      theme={theme}
      className={`w-full ${widthClassesByBoxType[type]}`}
    >
      <BoxContent
        title={title}
        description={description}
        overlineText={overlineText}
        links={links}
        theme={theme}
        type={type}
      />
    </Box>
  </div>
);

export default BoxSection;
