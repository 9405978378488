import React, { FC, RefCallback } from 'react';

import CustomLink from '@/components/custom-link';
import Image from '@/components/image';
import TagChip from '@/components/tag-chip';
import { ContentFeedEntryCreator } from '@/types/views/generic';

type CreatorTeaserProps = Omit<ContentFeedEntryCreator, 'type'> & {
  imageWrapperRef: RefCallback<HTMLDivElement>;
};

const CreatorTeaser: FC<CreatorTeaserProps> = ({
  image,
  tag,
  link,
  imageWrapperRef,
}) => (
  <article className="flex flex-col items-center">
    <CustomLink
      {...link}
      className="overflow-hidden mb-4 w-full rounded-full block"
    >
      <div ref={imageWrapperRef}>
        <Image src={image.src} className="aspect aspect-1-1" />
      </div>
    </CustomLink>

    <TagChip tag={tag} />
  </article>
);

export default CreatorTeaser;
