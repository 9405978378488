import { FC } from 'react';

interface WideArrowCircleIconProps {
  fill?: string;
  className?: string;
}

export const WideArrowCircleIcon: FC<WideArrowCircleIconProps> = ({
  fill = 'currentColor',
  className,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      d="M15.6776 12.3426C15.8588 12.1501 15.8588 11.8499 15.6776 11.6574L15.4635 11.4298L9.74272 5.33661C9.56425 5.14653 9.26968 5.1255 9.06601 5.28829L8.42289 5.80237C8.19497 5.98455 8.17083 6.32239 8.37053 6.53513L13.1791 11.6578C13.3597 11.8502 13.3597 12.1498 13.1791 12.3422L8.37053 17.4649C8.17083 17.6776 8.19497 18.0154 8.42289 18.1976L9.06601 18.7117C9.26968 18.8745 9.56425 18.8535 9.74272 18.6634L15.4635 12.5702L15.6776 12.3426Z"
      fill={fill}
    />
  </svg>
);

export default WideArrowCircleIcon;
