import Image from '@/components/image';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useVariables from '@/lib/hooks/use-variables';

interface MobileTicketCardProps {
  image: string;
  title: string;
  modus: string;
  status?: boolean;
  categories: string;
  qrCode?: string;
  barcode?: string;
  openDetails: () => void;
}

const MobileTicketCard = ({
  image,
  title,
  status,
  categories,
  modus,
  qrCode,
  barcode,
  openDetails,
}: MobileTicketCardProps) => {
  const variables = useVariables();

  return (
    <div className="relative overflow-hidden  h-full w-[calc((((100vw-90px)/6)*4)+50px)] shadow-[0_0_16px_0_rgba(18,17,19,0.50)] rounded-[16px] aspect-[2/3]">
      {image && (
        <>
          <Image
            src={image}
            alt={title}
            basicLoader
            objectFit="cover"
            className="h-full w-full"
          />

          <div className="h-full w-full backdrop-blur-[32px] qr-code-card-gradient absolute top-0 left-0"></div>
        </>
      )}

      <div className="z-10 absolute flex flex-col justify-between items-center shrink-0 p-4 top-0 left-0 h-full w-full">
        <h4 className="text-h4 self-stretch">{title}</h4>

        <div>
          {qrCode && (
            <div className="p-2 h-32 w-32 rounded-2 bg-white">
              <Image
                src={qrCode}
                alt={title}
                basicLoader
                objectFit="cover"
                className="h-full w-full"
              />
            </div>
          )}
          {barcode && (
            <div className="pt-2 pb-[2px]">
              <div className="text-small text-white text-center">{barcode}</div>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(barcode);
                }}
                className="text-small text-pink text-center underline uppercase h-4 cursor-pointer"
              >
                Code kopieren
              </div>
            </div>
          )}
        </div>

        <div className="">
          <div className="grid grid-cols-2 gap-4">
            <div className="text-body2">
              {getVariablePlainText(
                variables['account-tickets-category-text'],
              ) || 'Kategorie: '}
            </div>
            <div className="text-body2">{categories}</div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-overline text-gray-3">Eingelöst</div>
            <div className="text-overline text-gray-3">
              {status ? 'Ja' : 'Nein'}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-overline text-gray-3">Modus</div>
            <div className="text-overline text-gray-3">{modus}</div>
          </div>

          <div
            onClick={openDetails}
            className="h-8 text-small text-white underline flex items-center cursor-pointer"
          >
            {getVariablePlainText(
              variables['account-tickets-mobile-info-text'],
            ) || 'Weitere Informationen zum Ticket'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileTicketCard;
