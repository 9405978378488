import React, { FC } from 'react';
import { colors } from 'tailwind/theme';

interface PlayIconSolidProps {
  className?: string;
  backgroundColor?: string;
  backgroundOpacity?: number;
  foregroundColor?: string;
  borderWidth?: number;
  onClick?: React.MouseEventHandler;
}

const PauseIconBigWithBorder: FC<PlayIconSolidProps> = ({
  className,
  backgroundColor = colors.black.DEFAULT,
  backgroundOpacity = 0.6,
  foregroundColor = colors.white,
  borderWidth = 3,
  onClick,
}) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <circle
      opacity={backgroundOpacity}
      cx="60"
      cy="60"
      r={(120 - borderWidth) / 2}
      fill={backgroundColor}
      stroke="white"
      strokeWidth={borderWidth}
    />

    <rect
      x="68.2754"
      y="39.3105"
      width="12.4138"
      height="41.3793"
      rx="1.5"
      fill={foregroundColor}
    />

    <rect
      x="39.3115"
      y="39.3105"
      width="12.4138"
      height="41.3793"
      rx="1.5"
      fill={foregroundColor}
    />
  </svg>
);

export default PauseIconBigWithBorder;
