import React, { FC } from 'react';
import { colors } from 'tailwind/theme';

interface PlayIconSolidProps {
  className?: string;
  backgroundColor?: string;
  backgroundOpacity?: number;
  foregroundColor?: string;
  borderWidth?: number;
  onClick?: React.MouseEventHandler;
}

const PlayIconBigWithBorder: FC<PlayIconSolidProps> = ({
  className,
  backgroundColor = colors.black.DEFAULT,
  backgroundOpacity = 0.6,
  foregroundColor = colors.white,
  borderWidth = 3,
  onClick,
}) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    className={className}
    onClick={onClick}
  >
    <circle
      opacity={backgroundOpacity}
      cx="60"
      cy="60"
      r={(120 - borderWidth) / 2}
      fill={backgroundColor}
      stroke="white"
      strokeWidth={borderWidth}
    />

    <path
      d="M77.2013 62.6151L53.3401 76.4601C51.3151 77.6338 48.7163 76.2126 48.7163 73.8463V46.1563C48.7163 43.7938 51.3113 42.3688 53.3401 43.5463L77.2013 57.3913C77.6619 57.6543 78.0448 58.0344 78.3112 58.4931C78.5775 58.9518 78.7178 59.4728 78.7178 60.0032C78.7178 60.5336 78.5775 61.0546 78.3112 61.5133C78.0448 61.972 77.6619 62.3521 77.2013 62.6151Z"
      fill={foregroundColor}
    />
  </svg>
);

export default PlayIconBigWithBorder;
