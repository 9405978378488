import cls from 'classnames';
import { CSSProperties, FC } from 'react';

import WideArrowCircleIcon from '@/components/icons/wide-arrow-circle-icon';

type Size = 'medium' | 'small';

interface CarouselNavButtonProps {
  id?: string;
  type: 'prev' | 'next';
  shouldDisplay?: boolean;
  smSize?: Size;
  mdSize?: Size;
  style?: CSSProperties;
  className?: string;
  hasInvertedThemeColor?: boolean;
}

const CarouselNavButton: FC<CarouselNavButtonProps> = ({
  id,
  type,
  shouldDisplay = true,
  style,
  smSize = 'medium',
  mdSize = 'medium',
  className,
  hasInvertedThemeColor = false,
}) => (
  <button
    id={id}
    className={cls(
      'items-center justify-center rounded-full bg-white text-black-light',
      hasInvertedThemeColor
        ? 'teair:bg-white teair:text-air-deepBlue'
        : 'teair:bg-air-deepBlue teair:text-white',
      smSize === 'medium' && 'w-12 h-12',
      smSize === 'small' && 'w-8 h-8',
      mdSize === 'medium' && 'md:w-12 md:h-12',
      mdSize === 'small' && 'md:w-8 md:h-8',
      shouldDisplay ? 'flex' : 'hidden',
      type === 'prev' && 'transform rotate-180',
      className,
    )}
    style={style}
  >
    <WideArrowCircleIcon
      className={cls({
        'w-8 h-8': smSize === 'medium',
        'w-6 h-6': smSize === 'small',
        'md:w-8 md:h-8': mdSize === 'medium',
        'md:w-6 md:h-6': mdSize === 'small',
      })}
    />
  </button>
);

export default CarouselNavButton;
