import cls from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import SwiperCore, { A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import CarouselNavButton from '@/components/carousel-nav-button';
import LoadingIcon from '@/components/icons/loading-icon';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useResizeObserver from '@/lib/hooks/use-resize-observer';
import useVariables from '@/lib/hooks/use-variables';
import { TeaserGroup } from '@/types/views/generic';

import styles from './styles';

SwiperCore.use([Navigation, A11y]);

interface CarouselProps {
  id: string;
  teaserGroup: TeaserGroup;
  teasers: JSX.Element[];
  isMobile: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  showLoadMore?: boolean;
  onLoadMore?: () => void;
  loadMoreText?: string;
  hasInvertedThemeColor?: boolean;
}

const slidesByTeaserGroup: Record<TeaserGroup, number> = {
  [TeaserGroup.Default]: 4,
  [TeaserGroup.Categories]: 8,
  [TeaserGroup.Memes]: 3,
  [TeaserGroup.PodcastsCreatorsChannels]: 5,
  [TeaserGroup.Videos]: 4,
  [TeaserGroup.Stations]: 5,
  [TeaserGroup.Prizes]: 4,
  [TeaserGroup.Contact]: 6,
};

const carouselNavButtonClasses = 'absolute z-20 transform -translate-y-1/2';

const Carousel: FC<CarouselProps> = ({
  id,
  teaserGroup,
  teasers,
  isMobile,
  isLoading = false,
  isError = false,
  isEmpty = false,
  showLoadMore = false,
  onLoadMore = () => null,
  loadMoreText = '',
  hasInvertedThemeColor = false,
}) => {
  const [swiperData, setSwiperData] = useState<SwiperCore | null>(null);

  const [itemImageHeight, setItemImageHeight] = useState(0);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [currentLoadedData, setCurrentLoadedData] = useState<number | null>(
    null,
  );

  const firstItemImageRef = useRef<HTMLDivElement>(null);

  const variables = useVariables();
  const loadMoreCtaText =
    loadMoreText || getVariablePlainText(variables['load-more-cta']) || '';

  useResizeObserver(firstItemImageRef, (rect) =>
    setItemImageHeight(rect.height),
  );

  const handleSlideChange = (swiper: SwiperCore) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const navIconStyle = {
    top: itemImageHeight / 2,
  };

  const onLoadMoreClick = async () => {
    onLoadMore();
    setCurrentLoadedData(teasers.length);
  };

  useEffect(() => {
    if (currentLoadedData !== null && currentLoadedData < teasers?.length) {
      setIsEnd(false);
      setCurrentLoadedData(null);

      if (swiperData) {
        if (isMobile) {
          swiperData.slideTo(currentLoadedData, 1000);
        } else {
          swiperData.slideTo(currentLoadedData - 1, 1000);
        }
      }
    }
  }, [teasers?.length, currentLoadedData, swiperData, isMobile]);

  if (!teasers?.length) return null;

  if (isError) {
    return <div>{getVariablePlainText(variables['content-not-loaded'])}</div>;
  }

  if (isEmpty && !isLoading) {
    return <div>{getVariablePlainText(variables['content-not-found'])}</div>;
  }

  return (
    <div className="relative md:overflow-hidden">
      <Swiper
        wrapperTag="ul"
        spaceBetween={12}
        preventClicksPropagation={false}
        slidesPerView={isMobile ? 'auto' : slidesByTeaserGroup[teaserGroup]}
        className={styles.swiper}
        navigation={{
          prevEl: `#carousel-navigation-prev-${id}`,
          nextEl: `#carousel-navigation-next-${id}`,
        }}
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => {
          handleSlideChange(swiper);
          setSwiperData(swiper);
        }}
      >
        <SwiperSlide
          key={teasers[0].key}
          tag="li"
          className={styles.slide(isMobile, teaserGroup)}
        >
          {React.cloneElement(teasers[0], {
            imageWrapperRef: firstItemImageRef,
          })}
        </SwiperSlide>

        {teasers.slice(1).map((teaser) => (
          <SwiperSlide
            key={teaser.key}
            tag="li"
            className={styles.slide(isMobile, teaserGroup)}
          >
            {teaser}
          </SwiperSlide>
        ))}

        {showLoadMore && (
          <SwiperSlide
            tag="li"
            className={styles.slide(isMobile, teaserGroup)}
            onClick={onLoadMoreClick}
          >
            <article className={styles.loadMoreSlide(teaserGroup)}>
              <div className={styles.loadMoreSlideWrapper(teaserGroup)}>
                <div className={styles.loadMoreSlideContent}>
                  {isLoading ? <LoadingIcon /> : loadMoreCtaText}
                </div>
              </div>
            </article>
          </SwiperSlide>
        )}
      </Swiper>

      {teasers.length >= slidesByTeaserGroup[teaserGroup] && (
        <>
          {!isMobile && (
            <>
              <CarouselNavButton
                id={`carousel-navigation-prev-${id}`}
                type="prev"
                style={navIconStyle}
                shouldDisplay={!isBeginning}
                className={cls('left-0', carouselNavButtonClasses)}
                hasInvertedThemeColor={hasInvertedThemeColor}
              />

              <CarouselNavButton
                id={`carousel-navigation-next-${id}`}
                type="next"
                style={navIconStyle}
                shouldDisplay={!isEnd}
                className={cls('right-0', carouselNavButtonClasses)}
                hasInvertedThemeColor={hasInvertedThemeColor}
              />
            </>
          )}

          {!isBeginning && (
            <div
              className={styles.leftGradient(isMobile, hasInvertedThemeColor)}
            />
          )}

          {!isEnd && (
            <div
              className={styles.rightGradient(isMobile, hasInvertedThemeColor)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Carousel;
